<style scoped>
.blinking-class {
  animation: blink-animation 1s infinite;
  color: yellow;
}

@keyframes blink-animation {
  0%,
  49%,
  100% {
    opacity: 1;
  }
  50%,
  99% {
    opacity: 0;
  }
}
</style>

<template>
  <span :class="{ 'blinking-class': isBlinking }"
    ><div>Auto-Logoff: {{ Time }}</div></span
  >
</template>

<script>
import moment from "moment";
import route from "vue-router";

export default {
  name: "countdownlogoff",
  data: function () {
    return {
      Time: "",
      Secs: 0,
      Activated: false,
      Interval: null,
      isBlinking: false,
      LastTime: null,
      TimeCompare: 0,
      IsLogged: null,
    };
  },
  props: {
    Seconds: {
      type: Number,
      default: 1200, // 5 min
    },
    Enabled: {
      type: Boolean,
      default: false,
    },
    Page: {
      type: String,
      default: "/Logoff",
    },
  },
  methods: {
    startBlinking() {
      this.isBlinking = true;
    },
    stopBlinking() {
      this.isBlinking = false;
    },
    CountDown() {
      if (this.Activated == true) {
        this.Secs--;

        var t = moment.duration(this.Secs, "seconds");
        this.Time = `${t.get("minutes").toString().padStart(2, 0)}:${t.get("seconds").toString().padStart(2, 0)}`;

        //Apenas para debug
        //console.log(this.Secs);
        //Debug.Write("this.Secs");

        var tempTimeStamp = new Date();
        var TimeStampNow = tempTimeStamp.getTime();

        if (this.LastTime == null) {
          this.LastTime = TimeStampNow;
        }

        // console.log("LastTime " + this.LastTime.toString());
        // console.log("Now " + TimeStampNow.toString());
        // console.log("CountDown " + this.Secs.toString());

        this.TimeCompare = Math.trunc(TimeStampNow / 1000) - Math.trunc(this.LastTime / 1000);
        this.LastTime = TimeStampNow;
        // console.log("Comparação " + this.TimeCompare.toString());

        if (this.Secs <= 60) {
          this.startBlinking();
        } else {
          this.stopBlinking();
        }

        if (this.Secs <= 0 || this.TimeCompare > 60) {
          this.stopBlinking();
          this.Activated = false;
          clearInterval(this.Interval);
          this.$router.push(this.Page);
        }
      }
    },
    setTimer() {
      this.Interval = setInterval(this.CountDown, 1000);
    },
    resetTimer() {
      clearInterval(this.Interval);
      //this.setTimer(); //Ajuste, comentando esta linha, para evitar a criação de uma segundo countdown , em teste
    },
  },
  watch: {
    //Este valor é alterado entre true e false, toda vez que um reuqisição ao backend e realizada
    //com isso
    "$store.state.CountDownLogoffReset": function () {
      this.Secs = this.Seconds;
    },
  },
  created() {
    this.Secs = this.Seconds;
    this.Activated = this.Enabled;
    this.setTimer();
  },
  destroyed() {
    this.resetTimer();
  },
};
</script>
