<template>
  <v-container fill-height fluid>
    <v-row class="grow" align="center" justify="center">
      <v-col lg="3" md="4" sm="7" xs="12">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Controle de Faturamento</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col></v-col>
                <v-col align="center">
                  <v-icon size="128" color="#4f4f4f">mdi-scale-balance</v-icon>
                </v-col>
                <v-col></v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field outlined dense hide-details label="Login" name="login" ref="login" prepend-inner-icon="mdi-account" type="text" v-model="usuario.Login" @keyup.native.enter="passwdFocus" />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field outlined dense hide-details id="password" label="Password" name="password" prepend-inner-icon="mdi-account-key" type="password" ref="passwd" v-model="usuario.Senha" @keyup.native.enter="Login()" />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn outlined color="primary" dark @click="Login()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ata"></v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      usuario: {
        Login: "",
        Senha: "",
      },
    };
  },
  methods: {
    Login() {
      axios({
        method: "post",
        url: "/api/Sistema/Usuarios/Login",
        data: this.usuario,
      })
        .then((response) => {
          this.$store.commit("SET_USROPE", response.data.Data);
          this.$store.commit("SET_HASHLOG", "");
          this.$store.commit("SET_USERLOGGED", true);
          this.$router.push("/home");
        })
        .catch((error) => {
          this.$store.commit("SET_USERLOGGED", false);
          this.$store.commit("SET_HASHLOG", "");
          this.$store.commit("SET_USROPE", {});
          this.loginFocus();
        })
    },
    passwdFocus() {
      this.$refs.passwd.focus();
    },
    loginFocus() {
      this.$refs.login.focus();
    },
  },
  mounted: function () {
    this.$store.commit("SET_USROPE", {});
    this.$store.commit("SET_USERLOGGED", false);
    this.loginFocus();
    var referrerPage = window.frames.top.document.referrer;
    var referrerPage2 = document.referrer;
    console.log("Referrer");
    console.log("=>", referrerPage, " =>", referrerPage2);
  },
};
</script>
