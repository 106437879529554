<template>
  <v-flex xs12 sm12 md12>
    <v-dialog ref="dialogTime" v-model="ShowModal" :return-value.sync="time" width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field outlined dense hide-details :readonly="readonly" :label="label" v-model="computedTime" v-mask="'##:##'">
          <template v-slot:prepend-inner>
            <v-icon :disabled="readonly" tabindex="-1" @click="(ShowModal = !ShowModal), (on)">
              mdi-clock-time-four-outline
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-time-picker v-if="ShowModal" :readonly="readonly" v-model="time" format="24hr" tabindex="-1" scrollable full-width>
        <v-btn outlined color="orange" @click="ShowModal = !ShowModal">Voltar</v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click="save(time)">OK</v-btn>
      </v-time-picker>
    </v-dialog>
  </v-flex>
</template>

<script>
export default {
  name: "HrPicker",
  data: function () {
    return {
      ShowModal: false,
      time: ''
    };
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    returnFalse() {
      this.$emit("cancel");
    },
    returnTrue() {
      this.$emit("confirm");
    },
    save(time) {
      this.$refs.dialogTime.save(time);
      this.$emit("input", time);
    },
  },
  computed: {
    computedTime: {
      get() {
        if (this.time != null && this.time.length >= 5) return this.time;
        else return "";
      },
      set(newValue) {
        if (newValue != null && newValue.length >= 5) {
          this.$emit("input", newValue);
          this.time = newValue;
        } else if (newValue == "") {
          this.$emit("input", null);
          this.time = null;
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != null && newVal.length >= 5) {
          this.time = newVal.substring(0, 5);
          this.$emit("input", newVal);
        } else {
          this.time = null;
          this.$emit("input", null);
        }
      },
    },
  },
};
</script>