<template>
  <v-flex xs12 sm12 md12>
    <v-dialog persistent v-model="showDialog" @keydown.enter="returnTrue()" @keydown.esc="returnFalse()" width="420px">
      <v-card>
        <v-app-bar color="primary" dense>
          <v-toolbar-title class="white--text">
            {{ Msg }}
          </v-toolbar-title>
        </v-app-bar>
        <v-card-actions>
          <br /><br /><br /><br /><br />
          <v-spacer></v-spacer>
          <v-btn color="error" @click="returnFalse" width="35%">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="returnTrue" width="35%">Sim</v-btn>
          <v-spacer></v-spacer>
          <br /><br /><br /><br /><br />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
export default {
  name: "confirm",
  props: ["Msg", "Show"],
  methods: {
    returnFalse() {
      this.$emit("cancel");
    },
    returnTrue() {
      this.$emit("confirm");
    },
  },
  computed: {
    showDialog() {
      return this.Show;
    },
  },
};
</script>
