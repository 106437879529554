<template>
  <div>
    <v-card height="100%" width="100%">
      <v-card-text class="pl-1 pr-1">
        <v-tabs v-model="Tab">
          <v-tab href="#Geral">Geral</v-tab>
          <v-tab href="#SMTP">Servidor de SMTP</v-tab>
          <v-tab href="#Omie">Omie</v-tab>
          <v-tab href="#ND">Nota de Débito</v-tab>
        </v-tabs>

        <v-tabs-items v-model="Tab">
          <v-tab-item transition="false" value="Geral">
            <br />
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="E-mail (TO))" v-model="Model.EmailTo"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="E-mail (CC))" v-model="Model.EmailCc"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="E-mail (CCO))" v-model="Model.EmailCco"></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item transition="false" value="SMTP">
            <br />
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Servidor SMTP" v-model="Model.EmailServidor"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Porta SMTP" v-model="Model.EmailPorta"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="SSL ?" v-model="Model.EmailSsl"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="E-mail Login" v-model="Model.EmailLogin"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined type="password" prepend-inner-icon="mdi-magnify" label="E-mail Senha" v-model="Model.EmailSenha"></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item transition="false" value="Omie">
            <br />
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Omie API Key" v-model="Model.OmieAPIKey"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Omie API Secret" v-model="Model.OmieAPISecret"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Omie Categoria Contas a Receber Clientes" v-model="Model.OmieCategoria"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Omie URL Base" v-model="Model.OmieURLBase"></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item transition="false" value="ND">
            <br />
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Título Pagamento" v-model="Model.TextoNDPagamentoTitulo"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-textarea hide-details dense outlined auto-grow prepend-inner-icon="mdi-magnify" label="Descrição Pagamento" v-model="Model.TextoNDPagamentoDesc"></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined auto-grow prepend-inner-icon="mdi-magnify" label="Path armazenamento Preliminares" v-model="Model.PathPreliminar"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
                <v-text-field hide-details dense outlined auto-grow prepend-inner-icon="mdi-magnify" label="Path armazenamento Faturamento" v-model="Model.PathFaturamento"></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <br />

        <v-row dense>
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
            <v-row>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block @click="Cancelar()" color="warning">Cancelar</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col></v-col>
              <v-col>
                <v-btn block @click="ShowConfirmaSalvar = true" color="primary">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Configuracoes",
  data() {
    return {
      Model: {},
      Tab: {},
      ShowDialog: true,
      ShowConfirmaSalvar: false,
    };
  },
  components: {},
  methods: {
    Index() {
      axios.post("/api/Sistema/Parametros/Index").then((response) => {
        this.Model = response.data.Data;
      });
    },
    Cancelar() {
      // if (!this.fromRelatorio) {
      this.$router.push("/Home").catch(() => {});
      // } else {
      // this.$emit("retorno");
      // }
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/Sistema/Parametros/Save" })
        .then((response) => {
          this.$toast.success("Registro salvo", { timeout: 5000 });
        })
        .catch((error) => {
          //
        });
    },
  },
  mounted: function () {
    this.Index();
  },
};
</script>
