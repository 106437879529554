<template>
  <div>
    <v-card>
      <v-card-text class="pl-1 pr-1">
        <v-row dense>
          <v-col cols="1" offset="1">
            <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly class="fundo-readonly"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
            <v-col cols="12" md="10" lg="10" offset="0" offset-md="1" offset-lg="1">
              <v-text-field outlined dense hide-details label="Nome" v-model.trim="Model.Nome"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="10" lg="10" offset="0" offset-md="1" offset-lg="1">
              <v-text-field outlined dense hide-details label="Endereço" v-model.trim="Model.Endereco"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="4" offset="1">
              <v-text-field outlined dense hide-details label="Bairro" v-model.trim="Model.Bairro"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense hide-details label="Município" v-model.trim="Model.Municipio"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense hide-details label="UF" v-model.trim="Model.Estado"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="10" lg="10" offset="0" offset-md="1" offset-lg="1">
              <v-text-field outlined dense hide-details label="E-Mail" v-model="Model.Email"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="5" offset="1">
              <v-text-field outlined dense hide-details label="Telefone" v-model="Model.Telefone1"></v-text-field>
            </v-col>
            <v-col cols="5" >
              <v-text-field outlined dense hide-details label="Telefone" v-model="Model.Telefone2"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="5" offset="1">
              <v-text-field outlined dense hide-details label="Empresa" v-model="Model.Empresa"></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field outlined dense hide-details label="Cargo" v-model="Model.Cargo"></v-text-field>
            </v-col>
          </v-row>

        <v-row dense class="pt-16">
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
            <v-row>
              <v-col>
                <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.Id != 0">Excluir Contato</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block @click="Cancelar()" color="warning">Cancelar</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block @click="ShowConfirmaSalvar = true" color="primary">{{ Model.Id == 0 ? "Incluir" : "Salvar" }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContatoEdit",
  props: {
    idContato: {
      type: Number,
      default: 0,
    },
    fromRelatorio: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      Contatos: [],
    };
  },
  methods: {
    Cancelar() {
      // if (!this.fromRelatorio) {
      this.$router.push("/Cadastro/Contatos/Index").catch(() => {});
      this.Index();
      // } else {
      // this.$emit("retorno");
      // }
    },
    Edit(Id) {
      axios.get("/api/Cadastro/Contatos/Edit/".concat(Id)).then((response) => {
        //Pega os dados do response e joga no objeto Model
        this.Model = response.data.Data;
      });
    },
    Save() {
      this.Model.FlagContato = "P";
      axios({ method: "post", data: this.Model, url: "/api/Cadastro/Contatos/Save" })
        .then((response) => {
          if (!this.fromRelatorio) this.Index();
          else this.$emit("retorno");
        })
        .catch((error) => {
          let a = 1;
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Cadastro/Contatos/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Cadastro/Contatos/Index");
    },
    GetListas() {
      const instance = axios.create();
      const GetContatos = instance.get("/api/Listas/Contatos");
      axios
        .all([GetContatos])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.Contatos.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
  },
  mounted: function () {
    //Pega a Id enviada pela rota
    let Id = this.$route.params.id;
    this.GetListas();

    if (Id != null) {
      //se for null, volta para o Index
      this.Edit(Id);
    } else {
      //this.$router.push("/Cadastro/Contatos/Index");
      this.Edit(this.idContato);
    }
  },
};
</script>
