<template>
  <v-autocomplete return-object :items="Itens" :item-text="ItemText" :item-value="ItemValue" v-model="computedItemModel" :label="Label" dense outlined hide-details>
    <template v-slot:prepend-item>
      <v-list-item-content>
        <v-list-item-title>
          <v-btn @click="returnClick" color="info" block>{{ BtnText }}<v-icon>{{ BtnIcon }}</v-icon></v-btn>
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item[ItemText]"></v-list-item-title>
        <v-list-item-subtitle v-if="ItemSubTitleItens" v-text="ItemSubTitleText(item)"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "selectbutton",
  data: function () {
    return {
      data: {},
    };
  },
  props: ["value", "Itens", "Label", "ItemText", "ItemSubTitleItens", "ItemValue", "BtnText", "BtnIcon"],
  methods: {
    returnClick() {
      this.$emit("clickbtn");
    },
    ItemSubTitleText(item){
      if(this.ItemSubTitleItens)
      {
        let text = [];
        for (var i = 0; i < this.ItemSubTitleItens.length; i++) {
          if(item[this.ItemSubTitleItens[i]])
            text.push(item[this.ItemSubTitleItens[i]])
        }
        return text.join(' / ');
      }      
    }
  },
  computed: {
    computedItemModel: {
      get() {
        this.data;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
