<template>
  <span>
    <span>
      <v-row>
        <v-col>
          {{ this.sJson }}
        </v-col>
      </v-row>
    </span>
  </span>
</template>

<script>
import axios from "axios";

export default {
  name: "confirm",
  components: {},
  data() {
    return {
      GridData: [],
      items: [],
      options: ["one", "two", "three"],
      selections: [{}, {}],
      sJson: null,
    };
  },
  computed: {},
  methods: {
    AddItem() {
      this.selections.push({});
    },
    // Index() {
    //   this.$router.options.routes.forEach((route) => {
    //     this.items.push({
    //       name: route.name,
    //       path: route.path,
    //     });
    //   });
    // },
  },
  mounted: function () {
    // this.Index();
    axios.get("/api/Cadastro/Pessoas/ImportarCliente").then((response) => {
      this.sJson = response;
    });
    axios.get("/api/Clicktime/Import").then((response) => {});
  },
};
</script>
