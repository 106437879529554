<template>
  <div class="bordaappbar">
    <v-data-table ref="dTable" class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="Header" :items="GridData" :item-class="RowClass" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()" :single-expand="singleExpand" :expanded.sync="expanded">
      <template v-slot:top>
        <v-toolbar dense flat class="mb-5">
          <v-col col="6">
            <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" @change="Index()"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn to="/Sistema/Menus/Edit/0" color="primary" v-if="CanEdit">
            Novo
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.Icon`]="{ item }">
        <span class="text-no-wrap">
          <span v-if="item.IdMenuPai != 0">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <v-icon>{{ item.Icon }}</v-icon>
          {{ item.ItemMenu }}
        </span>
      </template>

      <template v-slot:[`item.AllUsers`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="item.AllUsers == 1 ? 'success' : 'error'">{{ item.AllUsers == 1 ? "mdi-check-circle" : "" }}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.UpDown`]="{ item }" v-if="this.CanEdit">
        <v-icon @click="moveItemUp(item)" :disabled="isFirst(item)">mdi-arrow-up</v-icon>
        <v-icon @click="moveItemDown(item)" :disabled="isLast(item)">mdi-arrow-down</v-icon>
      </template>

      <template v-slot:[`item.Action`]="{ item }">
        <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="3" offset="4">
              <v-simple-table>
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">Usuário/Grupo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(acesso, index) in item.Acessos" height="30px" valign="middle">
                      <span v-if="acesso.IdGrupo">
                        <td>
                          <span>
                            <span v-if="acesso.ReadOnly">
                              <v-icon color="green">mdi-eye</v-icon>
                              <v-icon color="red">mdi-lead-pencil</v-icon>
                            </span>
                            <span v-else>
                              <v-icon color="green">mdi-eye</v-icon>
                              <v-icon color="green">mdi-lead-pencil</v-icon>
                            </span>
                          </span>
                        </td>
                        <td>
                          <span>
                            <v-icon>mdi-account-group</v-icon>
                            {{ acesso.Nome }}
                          </span>
                        </td>
                      </span>
                      <span v-else>
                        <td>
                          <span v-if="acesso.ReadOnly">
                            <v-icon color="green">mdi-eye</v-icon>
                            <v-icon color="red">mdi-lead-pencil</v-icon>
                          </span>
                          <span v-else>
                            <v-icon color="green">mdi-eye</v-icon>
                            <v-icon color="green">mdi-lead-pencil</v-icon>
                          </span>
                        </td>
                        <td>
                          <span>
                            <v-icon>mdi-account</v-icon>
                            {{ acesso.Nome }}
                          </span>
                        </td>
                      </span>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SistemaUsuariosIndex",
  data() {
    return {
      expanded: [7, 20],
      singleExpand: false,
      nomeUsuarioGrupo: null,
      GridData: [],
      GridDataTemp: [],
      Nome: null,
      showDialogCadastro: false,
      Header: [
        { text: "Id", value: "Id", sortable: false },
        { text: "Ícone", value: "Icon", sortable: false },
        { text: "Liberado", value: "AllUsers", sortable: false },
        { text: "Link", value: "Link", sortable: false },
        { text: "", value: "Seq", sortable: false },
        { text: "", value: "UpDown", sortable: false },
        { text: "", value: "Action", sortable: false },
        { text: "", value: "data-table-expand", sortable: false },
      ],
      RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      Pagination: {
        page: 1,
        itemsPerPage: 100,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["IdMenuPai", "Seq"],
        sortDesc: [false],
        Filtro: {
          Busca: "",
          Inativos: false,
        },
      },
      IsPermitted: 0,
      CanEdit: false,
      CanDelete: false,
      HideEdit: false,
      HideDelete: false,
    };
  },
  components: {},

  methods: {
    Edit(Id) {
      this.$router.push("/Sistema/Menus/Edit/".concat(Id));
    },
    Index() {
      this.GridDataTemp = [];
      this.GridData = [];
      axios.post("/api/Sistema/Menus/Index", this.Pagination).then((response) => {
        this.GridDataTemp = response.data.Data;
        this.Pagination = response.data.Pagination;

        var temp = this.GridDataTemp.sort((a, b) => a.Seq - b.Seq);
        var tempMenu = temp.filter((x) => x.IdMenuPai == 0);
        let Order = 1;
        let v;
        for (v of Object.values(tempMenu)) {
          v.Seq = Order;
          Order += 1;
          this.GridData.push(v);
          var tempSub = temp.filter((x) => x.IdMenuPai == v.Id);
          let OrderSub = 1;
          for (v of Object.values(tempSub)) {
            v.Seq = OrderSub;
            OrderSub += 1;
            this.GridData.push(v);
          }
        }
      });
    },
    RowClass(item) {
      if (item.FlagAtivo == false) {
        return "rowdisabled";
      }
    },
    expandAllIssues() {
      if (true) {
        let expandObject = {};
        for (const item of this.GridData) {
          expandObject[item.Id] = true;
        }
        this.$refs.dTable.expanded = expandObject;
      } else {
        this.expanded = [];
      }
    },
    // UpDown Button
    moveItemUp(item) {
      if (!this.isFirst(item)) {
        item.Seq -= 1;
        //this.sortList();
        axios({ method: "post", data: item, url: "/api/Sistema/Menus/Save" })
          .then((response) => {
            this.Index();
          })
          .catch((error) => {
            //
          });
      }
    },
    moveItemDown(item) {
      if (!this.isLast(item)) {
        item.Seq += 1;
        //this.sortList();
        axios({ method: "post", data: item, url: "/api/Sistema/Menus/Save" })
          .then((response) => {
            this.Index();
          })
          .catch((error) => {
            //
          });
      }
    },
    LoadPermission() {
      axios({ method: "get", data: null, url: "/api/Listas/GetAcesso" })
        .then((response) => {
          this.IsPermitted = 10; //response.data;
          this.HideEdit = true; //Boolean(this.$functions.EditPermitted(this.IsPermitted));
          this.CanEdit = true; //Boolean(this.$functions.EditPermitted(this.IsPermitted, 1));
          this.HideDelete = true; //Boolean(this.$functions.DeletePermitted(this.IsPermitted));
          this.CanDelete = true; //Boolean(this.$functions.DeletePermitted(this.IsPermitted, 1));
        })
        .catch((error) => {
          //
        });
    },
    isFirst(item) {
      return item.Seq === 1;
    },
    isLast(item) {
      return item.Seq === this.GridData.length;
    },
    sortList() {
      this.GridData.sort((a, b) => a.Seq - b.Seq);
    },
  },
  computed: {
    sortedList() {
      return [...this.GridData].sort((a, b) => a.Seq - b.Seq);
    },
  },
  mounted: function () {
    console.clear();
    this.LoadPermission();
    this.Index();
  },
};
</script>
