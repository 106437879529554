<template>
  <div>
    <v-card>
      <v-card-text class="pl-1 pr-1">
        <v-row dense>
          <v-col cols="1" offset="2">
            <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field outlined dense hide-details label="IdClicktime" v-model="Model.IdClicktime"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2" offset="2">
            <v-text-field outlined dense hide-details label="Sigla" v-model.trim="Model.Sigla"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field outlined dense hide-details label="Nome" v-model.trim="Model.Nome"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6" lg="6" offset="0" offset-md="2" offset-lg="2">
            <v-text-field outlined dense hide-details label="E-Mail" v-model="Model.Email"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
            <v-textarea outlined dense hide-details rows="3" label="Observação" v-model="Model.Observacao"></v-textarea>
          </v-col>
        </v-row>

        <v-row dense class="pt-16">
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
            <v-row>
              <v-col>
                <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.Id != 0">Excluir Lancamento</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block @click="Cancelar()" color="warning">Cancelar</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block @click="ShowConfirmaSalvar = true" color="primary">{{ Model.Id == 0 ? "Incluir" : "Salvar" }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LancamentoEdit",
  props: {
    idLancamento: {
      type: Number,
      default: 0,
    },
    fromRelatorio: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      Lancamentos: [],
    };
  },
  methods: {
    Cancelar() {
      // if (!this.fromRelatorio) {
      this.$router.push("/Lancamentos/Index").catch(() => {});
      this.Index();
      // } else {
      // this.$emit("retorno");
      // }
    },
    Edit(Id) {
      axios.get("/api/Lancamentos/Edit/".concat(Id)).then((response) => {
        //Pega os dados do response e joga no objeto Model
        this.Model = response.data.Data;
      });
    },
    Save() {
      this.Model.FlagLancamento = "P";
      axios({ method: "post", data: this.Model, url: "/api/Lancamentos/Save" })
        .then((response) => {
          if (!this.fromRelatorio) this.Index();
          else this.$emit("retorno");
        })
        .catch((error) => {
          let a = 1;
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Lancamentos/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Lancamentos/Index");
    },
    GetListas() {
      const instance = axios.create();
      const GetLancamentos = instance.get("/api/Listas/Lancamentos");
      axios
        .all([GetLancamentos])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.Lancamentos.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
  },
  mounted: function () {
    //Pega a Id enviada pela rota
    let Id = this.$route.params.id;
    this.GetListas();

    if (Id != null) {
      //se for null, volta para o Index
      this.Edit(Id);
    } else {
      //this.$router.push("/Cadastro/Lancamentos/Index");
      this.Edit(this.idLancamento);
    }
  },
};
</script>
