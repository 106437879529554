<template>
	<v-row>
    <v-col cols="6" offset="3" >
      <br>
      <v-alert border="right" colored-border type="error" elevation="2">
        403 - Acesso Negado
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
export default {
	name: 'AcessoNegado',
	data() { return {}; }
};
</script>