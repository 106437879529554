<template>
  <div>
    <v-card>
      <v-card-text class="pl-1 pr-1">
        <v-row dense>
          <v-col col="4" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
            <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
          </v-col>
          <v-col col="4" md="3" lg="1">
            <v-switch hide-details inset label="Ativo" color="primary" v-model="Model.FlagAtivo" :false-value="false" :true-value="true"></v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
            <v-text-field outlined dense hide-details label="Nome" v-model.trim="Model.Nome" ref="txtNome"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" offset="2">
            <v-data-table fixed-header :height="600" dense class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="HeaderController" :items="this.Controllers" hide-default-footer disable-pagination disabled-filtering>
              <template v-slot:top>
                <v-toolbar dense flat>
                  <v-toolbar-title>Controllers Disponíveis</v-toolbar-title>
                </v-toolbar>
                <hr />
              </template>

              <template v-slot:[`item.FlagPublico`]="{ item }">
                <v-icon :color="item.FlagPublico == 1 ? 'green' : 'red'">mdi-check-circle</v-icon>
              </template>

              <template v-slot:item.Action="{ item }">
                <v-icon @click="copiarItem(item)" hint="Adicionar Controller" color="success">mdi-chevron-triple-right</v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="4">
            <v-data-table dense class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="Header" :items="this.Model.Controllers" hide-default-footer disable-pagination disabled-filtering>
              <template v-slot:top>
                <v-toolbar dense flat>
                  <v-toolbar-title>Controllers do Grupo</v-toolbar-title>
                </v-toolbar>
                <hr />
              </template>

              <template v-slot:[`item.FlagPublico`]="{ item }">
                <v-icon :color="item.Controller.FlagPublico == 1 ? 'green' : 'red'">mdi-check-circle</v-icon>
              </template>

              <template v-slot:item.Action="{ item }">
                <v-icon @click="deletarItem(item)" hint="Excluir Controller" color="error">mdi-trash-can</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2" class="mt-10">
            <v-row>
              <v-col>
                <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.Id != 0">Excluir Grupo</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
              </v-col>
              <v-col>
                <v-btn block @click="ShowConfirmaSalvar = true" color="primary">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SistemaGrupoEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      Usuarios: [],
      Controllers: [],
      Membro: null,
      Header: [
        { text: "Controller/Action", value: "Controller.Nome", sortable: false },
        { text: "Público", value: "FlagPublico", sortable: false },
        { text: "", value: "Action", sortable: false, width: "70px", align: "center" },
      ],
      HeaderController: [
        { text: "Controller/Action", value: "Nome", sortable: false },
        { text: "Público", value: "FlagPublico", sortable: false },
        { text: "", value: "Action", sortable: false, width: "70px", align: "center" },
      ],
    };
  },
  methods: {
    Edit(Id) {
      axios.get("/api/Sistema/Grupos/Edit/".concat(Id)).then((response) => {
        this.Model = response.data.Data;
        this.OrdenaControllers();
        this.GetListas();
        this.Model.FlagGrupoDeController = true;
      });
    },
    OrdenaListaControllers() {
      this.Controllers = this.Controllers.sort(function (item1, item2) {
        // First sorting criteria, e.g. by active.data
        if (item1.Nome < item2.Nome) return -1;
        else if (item1.Nome == item2.Nome) return 0;
        else return 1;
      });
    },
    OrdenaControllers() {
      // Faço a ordenação do Nome da Controller/action
      var sortedItems = [];
      var sortedItemsFinal = [];
      // crio uma array separada com o Id e Nome
      for (var item of this.Model.Controllers) {
        sortedItems.push({ Id: item.Id, Nome: item.Controller.Nome, Info: item });
      }
      // dou o sorte na Array
      var x = sortedItems.sort(function (item1, item2) {
        if (item1.Nome < item2.Nome) return -1;
        else if (item1.Nome == item2.Nome) return 0;
        else return 1;
      });
      // recrio a Array original já ordenada
      for (var itemFinal of x) {
        sortedItemsFinal.push(itemFinal.Info);
      }
      // insiro a chave Controller já ordenada
      this.Model.Controllers = sortedItemsFinal;
    },
    Save() {
      this.Model.FlagGrupoDeController = true;
      axios({ method: "post", data: this.Model, url: "/api/Sistema/Grupos/Save" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Sistema/Grupos/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Sistema/GruposController/Index");
    },
    GetListas() {
      axios.get("/api/Listas/Controllers").then((response) => {
        this.Controllers = response.data;
        let v;
        for (v of Object.values(this.Model.Controllers)) {
          let posicao = this.Controllers.findIndex((x) => x.Nome === v.Controller.Nome);
          if (posicao >= 0) {
            this.Controllers.splice(
              this.Controllers.findIndex((x) => x.Nome === v.Controller.Nome),
              1
            );
          }
        }
        this.OrdenaListaControllers();
      });
    },
    copiarItem(item) {
      // Copiar o item da lista de origem para a lista de destino
      // controllers -> model.controllers
      this.Model.Controllers.push({ Id: 0, IdSistemaController: item.Id, IdSistemaGrupoController: this.Model.Id, Controller: item });
      this.Controllers.splice(this.Controllers.indexOf(item), 1);
      this.OrdenaControllers();
      this.OrdenaListaControllers();
    },
    deletarItem(item) {
      // Copiar o item da lista de origem para a lista de destino
      this.Controllers.push({ Id: item.Controller.Id, Nome: item.Controller.Nome });
      this.Model.Controllers.splice(this.Model.Controllers.indexOf(item), 1);
      this.OrdenaControllers();
      this.OrdenaListaControllers();
    },
  },
  computed: {
    computedDataGrid() {
      return this.Model.Controllers;
    },
    computedHeader() {
      return this.Header.filter((item) => {
        return item.value != "Usuario.Nome";
      });
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.Edit(id);
      this.$refs.txtNome.focus();
    } else {
      this.$router.push("/Sistema/GruposController/Index");
    }
  },
};
</script>
