<template>
	<div class="bordaappbar">
		<v-row class="mt-3" v-if="ShowPreliminar">
			<v-col col="8" md="6" lg="6" offset="0" offset-md="1" offset-lg="1">
				<v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="sBusca" v-on:keyup.enter="Index()"></v-text-field>
			</v-col>
		</v-row>
		<v-row v-if="ShowPreliminar">
			<v-col cols="12">
				<v-data-table disable-pagination class="elevation-1 row-pointer" :headers="HeaderFaturaveis" :items="GridFaturaveis" item-key="IdCaso" :hide-default-header="false" :hide-default-footer="true">
					<template v-slot:[`item.TotalLancamentos`]="{ item }">
						<span class="text-no-wrap">
							{{ FormataMoeda(item.TotalLancamentos, item.MoedaFaturamento) }}
						</span>
					</template>
					<template v-slot:[`item.TotalFaturaveis`]="{ item }">
						<span class="text-no-wrap">
							{{ FormataMoeda(item.TotalFaturaveis, item.MoedaFaturamento) }}
						</span>
					</template>
					<template v-slot:[`item.TotalDespesas`]="{ item }">
						<span class="text-no-wrap">
							{{ FormataMoeda(item.TotalDespesas, item.MoedaFaturamento) }}
						</span>
					</template>
					<template v-slot:[`item.DataPreliminar`]="{ item }">
						<span class="text-no-wrap">
							{{ item.DataPreliminar | dateBR }}
						</span>
					</template>
					<template v-slot:[`item.MoedaFaturamento`]="{ item }">
						<span class="text-no-wrap">
							<span v-if="item.MoedaFaturamento == 1">R$</span>
							<span v-if="item.MoedaFaturamento == 2">U$</span>
						</span>
					</template>
					<template v-slot:[`item.Action5`]="{ item }">
						{{ item.DataInicio | dateBR }}
						<br />
						{{ item.DataFim | dateBR }}
					</template>
					<template v-slot:[`item.Action3`]="{ item }">
						<v-btn @click="DownloadPreliminar(item.IdPreliminar)" color="primary" enabled="IdPreliminar>0" title="Download da Preliminar">
							<v-icon>mdi-download</v-icon>
						</v-btn>
					</template>
					<template v-slot:[`item.Action`]="{ item }">
						<v-btn @click="FiltraLancamentos(item)" color="primary" title="Detalhe da Preliminar">
							<v-icon>mdi-file-document-multiple-outline</v-icon>
							Detalhe
						</v-btn>
					</template>
					<template v-slot:[`item.Action2`]="{ item }">
						<v-btn @click="(ShowGeraFaturamento = true), (sIdCasoAFaturar = item)" color="success" v-if="PodeFaturar(item)">
							<v-icon>mdi-account-cash-outline</v-icon>
							Faturar
						</v-btn>
					</template>
					<template v-slot:[`item.Action4`]="{ item }">
						<v-btn @click="(ShowExcluiPreliminar = true), (itemPreliminar = item)" color="error" title="Excluir Preliminar">
							<v-icon>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<v-row v-if="ShowLancamentos">
			<v-col cols="12">
				<v-row dense class="mt-5 ml-5">
					<v-col cols="1" offset="0">
						<v-text-field label="Id" dense outlined readonly="true" v-model="IdPreliminar"></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-text-field label="Cliente" dense outlined readonly="true" v-model="DescCliente"></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-text-field label="Caso" dense outlined readonly="true" v-model="DescCaso"></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-btn @click="DownloadPreliminar(IdPreliminar)" color="primary">Download Preliminar</v-btn>
					</v-col>
					<v-col cols="2">
						<v-btn @click="EmitirPreliminar(CodCaso)" color="primary">Reemitir Preliminar</v-btn>
					</v-col>
					<v-col cols="1">
						<v-btn @click="Voltar()" color="primary">Voltar</v-btn>
					</v-col>
				</v-row>
				<v-row dense class="mt-0 ml-5">
					<v-col cols="12">
						<div class="text-body-1 text-center">
							<b>Período:</b>
							{{ sDataInicial }} a {{ sDataFinal }}
						</div>
						<br />
					</v-col>
				</v-row>

				<v-tabs v-model="Tab" fixed-tabs>
					<v-tab href="#lancamentos">LANÇAMENTOS</v-tab>
					<v-tab href="#despesas">DESPESAS</v-tab>
				</v-tabs>

				<v-tabs-items v-model="Tab">
					<v-tab-item transition="false" value="lancamentos" class="pa-1 tabcolor" style="min-height: 45vh">
						<v-data-table class="elevation-1 row-pointer" @click:row="RowSelectClick" :headers="HeaderLancamento" mobile-breakpoint="1" :items="GridDataLancamento" :item-class="RowClass" :sort-by.sync="Pagination.sortBy" :must-sort="true" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
							<template v-slot:[`item.DataLancamento`]="{ item }">
								<span class="text-no-wrap">
									{{ item.DataLancamento | dateBR }}
								</span>
							</template>

							<template v-slot:[`item.ValorLancamentoCalculado`]="{ item }">
								<span class="text-no-wrap">
									{{ FormataMoeda(item.ValorLancamentoCalculado, item.MoedaFaturamento) }}
								</span>
							</template>

							<template v-slot:[`item.ValorLancamentoFaturado`]="{ item }">
								<span class="text-no-wrap">
									{{ FormataMoeda(item.ValorLancamentoFaturado, item.MoedaFaturamento) }}
								</span>
							</template>

							<template v-slot:[`item.MoedaFaturamento`]="{ item }">
								<span class="text-no-wrap">
									<span v-if="item.MoedaFaturamento == 1">R$</span>
									<span v-if="item.MoedaFaturamento == 2">U$</span>
								</span>
							</template>

							<template v-slot:[`item.Action`]="{ item }">
								<v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
							</template>
						</v-data-table>
					</v-tab-item>

					<v-tab-item transition="false" value="despesas" class="pa-1 tabcolor" style="min-height: 45vh">
						<v-data-table class="elevation-1 row-pointer" @click:row="RowSelectClick" :headers="HeaderDespesa" mobile-breakpoint="1" :items="GridDataDespesa" :item-class="RowClass" :sort-by.sync="Pagination2.sortBy" :must-sort="true" :sort-desc.sync="Pagination2.sortDesc" item-key="Id" :options.sync="Pagination2" :server-items-length="Pagination2.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
							<template v-slot:[`item.Data`]="{ item }">
								<span class="text-no-wrap">
									{{ item.Data | dateBR }}
								</span>
							</template>

							<template v-slot:[`item.ValorLancamentoCalculado`]="{ item }">
								<span class="text-no-wrap">
									{{ FormataMoeda(item.Valor, item.MoedaFaturamento) }}
								</span>
							</template>

							<template v-slot:[`item.MoedaFaturamento`]="{ item }">
								<span class="text-no-wrap">
									<span v-if="item.MoedaFaturamento == 1">R$</span>
									<span v-if="item.MoedaFaturamento == 2">U$</span>
								</span>
							</template>

							<template v-slot:[`item.Action`]="{ item }">
								<v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
							</template>
						</v-data-table>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>

		<confirm Msg="Confirma a geração do Faturamento ?" :Show="ShowGeraFaturamento" v-on:confirm="GeraFaturamento(sIdCasoAFaturar), (ShowGeraFaturamento = false)" v-on:cancel="ShowGeraFaturamento = false"></confirm>

		<confirm Msg="Confirma a exclusão Preliminar ?" :Show="ShowExcluiPreliminar" v-on:confirm="ExcluirPreliminar(itemPreliminar), (ShowExcluiPreliminar = false)" v-on:cancel="ShowExcluiPreliminar = false"></confirm>
	</div>
</template>

<script>
import axios from "axios";
import moment, { relativeTimeThreshold } from "moment";

export default {
	name: "Lancamento",
	data() {
		return {
			ShowPreliminar: true,
			ShowGeraFaturamento: false,
			ShowExcluiPreliminar: false,
			ShowLancamentos: false,
			GridDataLancamento: [],
			GridDataDespesa: [],
			GridFaturaveis: [],
			DescCliente: "",
			DescCaso: "",
			IdPreliminar: 0,
			CodCaso: 0,
			Tab: {},
			sBusca: "",
			sBusca2: "",
			sDataInicial: "",
			sDataFinal: "",
			sIdCasoAFaturar: 0,
			HeaderLancamento: [
				{ text: "Id", value: "Id", width: "100", sortable: false },
				{ text: "Data", value: "DataLancamento", sortable: false },
				// { text: "Cliente", value: "Cliente.Nome", width: "20%", sortable: false },
				// { text: "Caso", value: "Caso.Nome", width: "20%", sortable: false },
				{ text: "Advogado", value: "Advogado.Nome", width: "15%", sortable: false },
				{ text: "Descrição", value: "Descricao", width: "45%", sortable: false },
				{ text: "Horas", value: "Horas", sortable: false },
				{ text: "Valor Calculado", value: "ValorLancamentoCalculado", sortable: false },
				{ text: "Valor Faturado", value: "ValorLancamentoFaturado", sortable: false },
				{ text: "", value: "Action", sortable: false },
			],
			HeaderDespesa: [
				{ text: "Id", value: "Id", width: "100", sortable: false },
				{ text: "Data", value: "Data", sortable: false },
				// { text: "Cliente", value: "Cliente.Nome", width: "20%", sortable: false },
				// { text: "Caso", value: "Caso.Nome", width: "20%", sortable: false },
				{ text: "Advogado", value: "Advogado.Nome", width: "25%", sortable: false },
				{ text: "Descrição", value: "Descricao", width: "35%", sortable: false },
				{ text: "Valor", value: "Valor", sortable: false },
				{ text: "", value: "Action", sortable: false },
			],
			HeaderFaturaveis: [
				{ text: "Id", value: "Id", align: "left", width: "30px", sortable: false },
				{ text: "Cliente", value: "Cliente", align: "left", width: "15%", sortable: false },
				// { text: "Impressao", value: "NomeImpressao", width: "20%", sortable: false },
				{ text: "Caso", value: "Caso", width: "20%", sortable: false },
				{ text: "Advogado", value: "Advogado", width: "15%", sortable: false },
				{ text: "Lançamentos", value: "TotalLancamentos", align: "center", width: "5%", sortable: false },
				{ text: "Faturáveis", value: "TotalFaturaveis", align: "center", width: "5%", sortable: false },
				{ text: "Despesas", value: "TotalDespesas", align: "center", width: "5%", sortable: false },
				{ text: "Preliminar", value: "DataPreliminar", align: "center", width: "5%", sortable: false },
				{ text: "Período", value: "Action5", align: "center", width: "10%", sortable: false },
				{ text: "", value: "Action3", sortable: false },
				{ text: "", value: "Action", sortable: false },
				{ text: "", value: "Action2", sortable: false },
				{ text: "", value: "Action4", sortable: false },
			],
			RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
			Pagination: {
				page: 1,
				itemsPerPage: 50,
				pageCount: 1,
				itemsLength: 0,
				sortBy: ["DataLancamento"],
				sortDesc: [false],
				Filtro: {
					Busca: "",
					IdCaso: 0,
					IdPreliminar: 0,
				},
			},
			Pagination2: {
				page: 1,
				itemsPerPage: 50,
				pageCount: 1,
				itemsLength: 0,
				sortBy: ["Data"],
				sortDesc: [true],
				Filtro: {
					Busca: "",
					IdCaso: 0,
					IdPreliminar: 0,
				},
			},
		};
	},
	methods: {
		Edit(Id) {
			this.$router.push("/Preliminar/Edit/".concat(Id));
		},
		Index() {
			this.MostraFaturaveis();
		},
		RowClass(item) {
			if (item.Ativo == false) {
				return "rowdisabled";
			}
		},
		FormataMoeda(valor, moeda) {
			var sPreMoeda = "";
			if (moeda == 1) {
				sPreMoeda = "R$ ";
			} else if (moeda == 2) {
				sPreMoeda = "U$ ";
			}
			return sPreMoeda.concat(
				parseFloat(valor)
					.toFixed(2)
					.replace(".", ",")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
			);
		},
		MostraFaturaveis() {
			this.ShowLancamentos = false;
			this.ShowPreliminar = true;
			debugger;
			axios.get("/api/Preliminares/Faturaveis/".concat(this.sBusca))
				.then((response) => {
					this.GridFaturaveis = response.data;
				})
				.catch(function (error) {
					//this.$toast.error("Erro.", { timeout: 3000 });
				});
		},

		EmitirPreliminar(IdCaso) {
			var CasosSelected = [];
			CasosSelected.push(IdCaso);
			debugger;
			this.sDataInicial = moment(this.sDataInicial, "DD-MM-YYYY").format("YYYY-MM-DD");
			this.sDataFinal = moment(this.sDataFinal, "DD-MM-YYYY").format("YYYY-MM-DD");
			axios({ method: "post", data: CasosSelected, url: "/api/Preliminares/ImprimirPreliminar/".concat(this.sDataInicial).concat("/").concat(this.sDataFinal).concat("/").concat(this.IdPreliminar) }).then((response) => {
				this.$toast.success("Preliminar geradas em PDFs e enviadas por e-mail ao responsável...", { timeout: 5000 });
				// const url = window.URL.createObjectURL(new Blob([response.data]));
				// const link = document.createElement("a");
				// link.href = url;
				// link.setAttribute("download", "Faturaveis.pdf");
				// document.body.appendChild(link);
				// link.click();
				// document.body.removeChild(link);
			});
		},
		ExcluirPreliminar(item) {
			axios.get("/api/Preliminares/ExcluirPreliminar/".concat(item.IdPreliminar)).then((response) => {
				this.$toast.clear();
				this.MostraFaturaveis();
				if (response.data.erro_status == 0) {
				}
				this.$toast.success("Preliminar excluído e lançamentos liberados.", { timeout: 2000 });
			});
		},

		FiltraLancamentos(item) {
			this.ShowLancamentos = true;
			this.ShowPreliminar = false;
			this.DescCliente = item.Cliente;
			this.DescCaso = item.Caso;
			this.CodCaso = item.IdCaso;
			this.IdPreliminar = item.IdPreliminar;

			this.Pagination.Filtro.IdPreliminar = item.IdPreliminar;
			axios.post("/api/Preliminares/Index", this.Pagination).then((response) => {
				this.GridDataLancamento = response.data.Data;
				this.Pagination = response.data.Pagination;

				this.Pagination2.Filtro.IdPreliminar = item.IdPreliminar;
				axios.post("/api/Preliminares/Index2", this.Pagination2).then((response) => {
					this.GridDataDespesa = response.data.Data;
					this.Pagination2 = response.data.Pagination;

					axios.get("/api/Listas/PreliminarControle/".concat(item.IdPreliminar)).then((response) => {
						this.sDataInicial = this.formatarData(response.data[0].DataInicial);
						this.sDataFinal = this.formatarData(response.data[0].DataFinal);
					});
				});
			});
		},

		formatarData(dataHoraString) {
			// Criar um objeto Date a partir da string
			const data = new Date(dataHoraString);

			// Obter dia, mês e ano
			const dia = String(data.getDate()).padStart(2, "0");
			const mes = String(data.getMonth() + 1).padStart(2, "0"); // Os meses começam do 0
			const ano = data.getFullYear();

			// Retornar no formato dd/MM/yyyy
			return `${dia}/${mes}/${ano}`;
		},

		PodeFaturar(item) {
			if (item.DataPreliminar != null) {
				return true;
			} else {
				return false;
			}
		},

		// Download do PDF gerado
		DownloadPreliminar(IdPreliminar) {
			axios({ method: "post", responseType: "blob", url: "/api/Listas/DownloadPreliminar/".concat(IdPreliminar) }).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				var sNomeArquivo = response.headers["content-disposition"]
					.split(";")[1]
					.split("=")[1]
					.replaceAll('"', "")
					.replace(/^.*[\\\/]/, "");
				const link = document.createElement("a");
				link.href = url;
				//link.setAttribute("download", item.Cliente.Nome + "_" + item.Caso.Nome + ".pdf");
				link.setAttribute("download", sNomeArquivo);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				// const url = window.URL.createObjectURL(new Blob([response.data]));
				// const link = document.createElement("a");
				// link.href = url;
				// link.setAttribute("download", item.Cliente + "-" + item.Caso + ".pdf");
				// document.body.appendChild(link);
				// link.click();
				// document.body.removeChild(link);
			});
		},
		GeraFaturamento(item) {
			var sRazaoSocial = "";
			this.$toast.success("Gerando Faturamento, Aguarde...", { timeout: 3000 });
			// vejo se o cliente tem cadastro no Omnie
			axios.get("/api/Listas/Clientes/".concat(item.IdCliente)).then((response) => {
				if (response.data[0].IdOmie == 0) {
					this.$toast.clear();
					sRazaoSocial = response.data[0].Nome;
					this.$toast.warning('Cliente "' + sRazaoSocial + '" não possui cadastro no Omie.', { timeout: 3000 });
					// Faço o cadastro do cliente no Omnie
					axios.get("/api/Cadastro/Pessoas/ClienteOmnie/".concat(item.IdCliente).concat("/").concat(response.data[0].Nome)).then((response) => {
						// Faço o faturamento em si

						this.$toast.success('Cliente "' + sRazaoSocial + '" foi cadastrado no Omnie.', { timeout: 3000 });
						axios.get("/api/Faturamento/EmitirFaturamento/".concat(item.IdCaso).concat("/").concat(item.IdCliente).concat("/").concat(item.IdPreliminar)).then((response) => {
							var sJsonRetorno = response.data;
							this.MostraFaturaveis();
							if (sJsonRetorno.codigo_status == 0) {
								this.$toast.success("Faturamento gerado.", { timeout: 3000 });
							} else {
								this.$toast.error("Processamento com erro,\n fatura já emitida ou sem contato com o Omie", { timeout: 5000 });
							}
						});
					});
					return;
				}
				axios.get("/api/Faturamento/EmitirFaturamento/".concat(item.IdCaso).concat("/").concat(item.IdCliente).concat("/").concat(item.IdPreliminar)).then((response) => {
					var sJsonRetorno = response.data;
					this.MostraFaturaveis();
					if (sJsonRetorno.codigo_status == 0) {
						this.$toast.success("Faturamento gerado.", { timeout: 3000 });
					} else {
						this.$toast.error("Processamento com erro,\n fatura já emitida ou sem contato com o Omie", { timeout: 5000 });
					}
				});
			});
		},
		Voltar() {
			this.MostraFaturaveis();
		},
	},
	mounted: function () {
		// this.Index();
		this.MostraFaturaveis();
	},
};
</script>
