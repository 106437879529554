<template>
  <div class="bordaappbar">
    <v-data-table class="elevation-1 row-pointer" mobile-breakpoint="1" :headers="Header" :items="GridData" :item-class="RowClass" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
      <template v-slot:top>
        <v-toolbar dense flat class="mb-5">
          <v-col cols="6">
            <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" @change="Index()"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn to="/Sistema/Permissoes/Edit/0" color="primary">
            Nova
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.Acesso`]="{ item }">
        <template v-if="item.FlagGrupoDeController == true">
          <v-icon color="warning">mdi-application-array-outline</v-icon>
          {{ item.GrupoController.Nome }}
        </template>
        <template v-else>
          <v-icon color="info">mdi-application-braces-outline</v-icon>
          {{ item.Controller.Nome }}
        </template>
      </template>

      <template v-slot:[`item.Para`]="{ item }">
        <template v-if="item.FlagPermissaoDeGrupo == true">
          <v-icon color="warning">mdi-account-group</v-icon>
          {{ item.GrupoUsuario.Nome }}
        </template>
        <template v-else>
          <v-icon color="info">mdi-account</v-icon>
          {{ item.Usuario.Nome }}
        </template>
      </template>

      <template v-slot:[`item.FlagIndex`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="item.FlagIndex == true ? 'success' : 'error'">{{ item.FlagIndex == true ? "mdi-check-circle" : "mdi-close-circle" }}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.FlagEdit`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="item.FlagEdit == true ? 'success' : 'error'">{{ item.FlagEdit == true ? "mdi-check-circle" : "mdi-close-circle" }}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.FlagSave`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="item.FlagSave == true ? 'success' : 'error'">{{ item.FlagSave == true ? "mdi-check-circle" : "mdi-close-circle" }}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.FlagDelete`]="{ item }">
        <span class="text-no-wrap">
          <v-icon :color="item.FlagDelete == true ? 'success' : 'error'">{{ item.FlagDelete == true ? "mdi-check-circle" : "mdi-close-circle" }}</v-icon>
        </span>
      </template>

      <template v-slot:[`item.Action`]="{ item }">
        <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SystemPermission",
  data() {
    return {
      GridData: [],
      Header: [
        { text: "Id", value: "Id", sortable: false },
        { text: "Nome", value: "Nome", sortable: false, width: "30%" },
        { text: "Controller/Grupo", value: "Acesso", sortable: false, width: "30%" },
        { text: "Usuário/Grupo", value: "Para", sortable: false, width: "30%" },
        { text: "Index", value: "FlagIndex", sortable: false, width: "30px" },
        { text: "Edit", value: "FlagEdit", sortable: false, width: "30px" },
        { text: "Save", value: "FlagSave", sortable: false, width: "30px" },
        { text: "Delete", value: "FlagDelete", sortable: false, width: "30px" },
        { text: "", value: "Action", sortable: false, width: "30px" },
      ],
      RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
      Pagination: {
        page: 1,
        itemsPerPage: 100,
        pageCount: 1,
        itemsLength: 0,
        sortBy: ["Controller.Nome"],
        sortDesc: [false],
        Filtro: {
          Busca: "",
          Inativos: false,
        },
      },
      showDialogCadastro: false,
    };
  },
  components: {},
  methods: {
    Edit(Id) {
      this.$router.push("/Sistema/Permissoes/Edit/".concat(Id));
    },
    Index() {
      axios.post("/api/Sistema/Permissoes/Index", this.Pagination).then((response) => {
        this.GridData = response.data.Data;
        this.Pagination = response.data.Pagination;
      });
    },
    RowClass(item) {
      if (item.FlagAtivo == false) {
        return "rowdisabled";
      }
    },
  },
  mounted: function () {
    this.Index();
  },
};
</script>
