import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

Vue.use(Vuex)
// Vue.config.devtools = false
// Vue.config.debug = false
// Vue.config.silent = true

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    usrOpe: {
      Login: "Login",
      Nome: "Nome Usuário",
    },
    showLoading: false,
    usrLogged: false,
    CountDownLogoffReset: false,
    TituloTela: "",
    ModoRelatorio: false,
    menuId: null,
    HashLog: null,
  },
  getters: {
    usrLogged: (state) => {
      return state.usrLogged;
    },
    usrOpe: (state) => {
      return state.usrOpe;
    },
    showLoading: (state) => {
      return state.showLoading;
    },
    CountDownLogoffReset: (state) => {
      return state.CountDownLogoffReset;
    },
    TituloTela: (state) => {
      return state.TituloTela;
    },
    ModoRelatorio: (state) => {
      return state.ModoRelatorio;
    },
    menuId: (state) => {
      return state.menuId;
    },
  },
  mutations: {
    SET_USROPE(state, usr) {
      state.usrOpe = usr;
    },
    SET_LOADING(state, Loading) {
      state.showLoading = Loading;
    },
    SET_USERLOGGED(state, logged) {
      state.usrLogged = logged;
    },
    SET_COUNTDOWNLOGOFFRESET(state, reset) {
      state.CountDownLogoffReset = reset;
    },
    SET_TITULOTELA(state, titulo) {
      state.TituloTela = titulo;
    },
    SET_MENUID(state, Id) {
      state.menuId = Id;
    },
    SET_MODORELATORIO(state, relatorio) {
      state.ModoRelatorio = relatorio;
    },
    SET_HASHLOG(state, HashLog) {
      state.HashLog = HashLog;
    },
  },
  actions: {},
  modules: {},
});
