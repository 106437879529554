<template>
  <v-snackbar :color="snackbar.color" :timeout="snackbar.timeout" :stackable="true" app top dense multi-line right v-model="snackbar.show" class="d-print-none">
    <!-- snackbar.color -->
    <section v-html="snackbar.msg"></section>
    <template v-slot:[`action`]="{ attrs }">
      <v-btn text v-on:click="snackbar.show = false" v-bind="attrs">
        <v-icon dark>mdi-close-box-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "snackbar",
  props: ["show", "msg", "color"],
  data: () => ({
    snackbar: {
      show: false,
      color: "red",
      msg: "",
      timeout: 6000 * 1,
    },
  }),
  methods: {
    Close() {
      this.$emit("cancel");
    },
  },
  computed: {
    showDialog() {
      return this.Show;
    },
  },
  mounted() {
    this.snackbar.show = show;
    this.snackbar.msg = msg;
    this.snackbar.color = color;
  },
};
</script>
