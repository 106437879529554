<style scoped>
.financial-dashboard {
	padding: 20px;
}
.chart-container {
	margin-top: 20px;
	height: 400px;
}
</style>
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.3/Chart.min.js"></script> -->

<template>
	<!-- <v-container> -->
		<div>
		<!-- Valores a Receber por Data -->
		<v-row>
			<v-col cols="3">
				<v-card>
					<v-card color="primary" class="mx-auto pa-5" id="DolarHoje">
						<v-card-title id="DolarHoje">
							<div>
								<!-- <div class="text-overline mb-1">kkk</div> -->
								<div class="text-h5 mb-1 white--text">Cotação Dólar Venda</div>
								<div class="text-h5 mb-1 yellow--text font-weight-bold">R$ {{ this.DolarHoje }}</div>
							</div>
						</v-card-title>
						<!-- <v-card-actions><v-btn>Button</v-btn></v-card-actions> -->
					</v-card>
				</v-card>
			</v-col>

			<v-col cols="3">
				<v-card>
					<v-card color="primary" class="mx-auto pa-5" id="Importacao">
						<v-card-title id="Importacao">
							<div>
								<!-- <div class="text-overline mb-1">kkk</div> -->
								<div class="text-h5 mb-1 white--text">Última Importação</div>
								<div class="text-h5 mb-1 yellow--text font-weight-bold">{{ this.Model.UltimaImportacao | dateBR }}</div>
							</div>
						</v-card-title>
						<!-- <v-card-actions><v-btn>Button</v-btn></v-card-actions> -->
					</v-card>
				</v-card>
			</v-col>

			<v-col cols="3">
				<v-card>
					<v-card color="primary" class="mx-auto pa-5" id="AReceber">
						<v-card-title>
							<div>
								<!-- <div class="text-overline mb-1">kkk</div> -->
								<div class="text-h5 mb-1 white--text">Faturas a vencer</div>
								<div class="text-h5 mb-1 yellow--text font-weight-bold">{{ this.Model.TotalValoresAReceber | currencyBR }}</div>
							</div>
						</v-card-title>
						<!-- <v-card-actions><v-btn>Button</v-btn></v-card-actions> -->
					</v-card>
				</v-card>
			</v-col>

			<v-col cols="3">
				<v-card>
					<v-card color="primary" class="mx-auto pa-5" id="Atrasados">
						<v-card-title>
							<div>
								<!-- <div class="text-overline mb-1">kkk</div> -->
								<div class="text-h5 mb-1 white--text">Faturas em atraso</div>
								<div class="text-h5 mb-1 yellow--text font-weight-bold">{{ this.Model.TotalValoresAtrasados | currencyBR }}</div>
							</div>
						</v-card-title>
						<!-- <v-card-actions><v-btn>Button</v-btn></v-card-actions> -->
					</v-card>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col col="6">
				<template>
					<v-card>
						<v-app-bar color="primary" dense>
							<v-toolbar-title class="white--text">Faturas a vencer</v-toolbar-title>
						</v-app-bar>
						<v-card-text class="bordaappbar">
							<canvas id="Chart1"></canvas>
						</v-card-text>
					</v-card>
				</template>
			</v-col>

			<v-col col="6">
				<v-card>
					<v-app-bar color="primary" dense>
						<v-toolbar-title class="white--text">Fluxo de Recebimentos</v-toolbar-title>
					</v-app-bar>
					<v-card-text class="bordaappbar">
						<canvas id="Chart2"></canvas>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col col="6">
				<v-card>
					<v-app-bar color="primary" dense>
						<v-toolbar-title class="white--text">Faturas a vencer</v-toolbar-title>
					</v-app-bar>
					<v-card-text class="bordaappbar">
						<v-data-table class="elevation-1 row-pointer" :headers="HeaderAFaturar" mobile-breakpoint="1" :items="Model2" :hide-default-footer="true">
							<template v-slot:[`item.ValoresAFaturar`]="{ item }">
								<span class="text-no-wrap">
									{{ item.ValoresAFaturar | currencyBR }}
								</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col col="6">
				<v-card>
					<v-app-bar color="primary" dense>
						<v-toolbar-title class="white--text">Faturas em atraso</v-toolbar-title>
					</v-app-bar>
					<v-card-text class="bordaappbar">
						<v-data-table class="elevation-1 row-pointer" :headers="HeaderAtrasados" mobile-breakpoint="1" :items="Model3" :hide-default-footer="true">
							<template v-slot:[`item.ValoresAFaturar`]="{ item }">
								<span class="text-no-wrap">
									{{ item.ValoresAFaturar | currencyBR }}
								</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
	<!-- </v-container> -->
</template>

<script>
import axios from "axios";
import moment, { relativeTimeThreshold } from "moment";
import { Line, Bar } from "vue-chartjs";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
	name: "Home",
	data() {
		return {
			Model: [],
			Model2: [],
			Model3: [],
			TotalModel2: 0,
			DolarHoje: 1,
			MostraGraph: false,
			valoresAFaturarPorClienteChart: null,
			valoresAReceberPorClienteChart: null,
			valoresAtrasadosPorDataChart: null,
			valoresAReceberPorDataChart: null,
			HeaderAFaturar: [
				{ text: "", value: "", align: "left", width: "10%", sortable: false },
				{ text: "NomeCliente", value: "NomeCliente", align: "left", width: "50%", sortable: false },
				{ text: "ValoresAFaturar", value: "ValoresAFaturar", align: "right", width: "50%", sortable: false },
				{ text: "", value: "", align: "left", width: "10%", sortable: false },
			],
			HeaderAtrasados: [
				{ text: "", value: "", align: "left", width: "10%", sortable: false },
				{ text: "NomeCliente", value: "NomeCliente", align: "left", width: "50%", sortable: false },
				{ text: "ValoresAFaturar", value: "ValoresAFaturar", align: "right", width: "50%", sortable: false },
				{ text: "", value: "", align: "left", width: "10%", sortable: false },
			],
			RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
			Pagination: {
				page: 1,
				itemsPerPage: 50,
				pageCount: 1,
				itemsLength: 0,
				sortBy: ["NomeCliente"],
				sortDesc: [false],
				Filtro: {
					Busca: "",
				},
			},
		};
	},

	components: {},
	methods: {
		PopulaDados() {
			axios.get("/api/Faturamento/Dashboard").then((response) => {
				this.Model = response.data;
				this.Model2 = response.data.ValoresAFaturarPorCliente;
				this.TotalModel2 = this.Model2.reduce((a, b) => a + (b["ValoresAFaturar"] || 0), 0);
				this.Model3 = response.data.ValoresAtrasadosPorCliente;
			});
		},
		DashBoard() {
			this.criaGrafico(
				"Chart1",
				"bar",
				this.Model.ValoresAReceberPorCliente.map((item) => item.NomeCliente),
				this.Model.ValoresAReceberPorCliente.map((item) => item.ValoresAReceber),
				"A Receber por Cliente"
			);

			this.criaGrafico(
				"Chart2",
				"bar",
				this.Model.ValoresAReceberPorData.map((item) => this.formatData(item.DataVencimento)),
				this.Model.ValoresAReceberPorData.map((item) => item.ValoresAReceber),
				"A Receber por Data"
			);
		},
		criaGrafico(ctx2, tipo, labels, data, title) {
			var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
			var ctx = document.getElementById(ctx2);
			var ChartX = new Chart(ctx, {
				type: "bar",
				data: {
					labels: labels,
					datasets: [
						{
							data: data,
							backgroundColor: bgColor,
						},
					],
				},
				options: {
					plugins: {
						title: {
							display: false,
							text: title,
							position: "top",
							align: "center",
							font: {
								size: 24,
								forecolor: "black",
							},
						},
						legend: {
							display: false,
							position: "right",
						},
						scales: {
							// xAxes: [{ ticks: { min: 40, max: 160 } }],
							// yAxes: [{ ticks: { min: 6, max: 16 } }],
						},
					},
				},
			});
			// ChartX.destroy();
		},
		formatData(date) {
			return moment(date).format("DD/MM/YYYY");
		},

		formatCurrency(value) {
			return value;
		},

		async GetDolarHoje() {
			await axios.get("/api/Listas/GetDolarHoje").then((response) => {
				console.log(response.data);
				this.DolarHoje = response.data;
				return response.data;
			});
		},
		FormataMoeda(valor, moeda) {
			var sPreMoeda = "";
			if (moeda == 1) {
				sPreMoeda = "R$ ";
			} else if (moeda == 2) {
				sPreMoeda = "U$ ";
			}
			return sPreMoeda.concat(
				parseFloat(valor)
					.toFixed(2)
					.replace(".", ",")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
			);
		},
		async sleep(seconds) {
			return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
		},
	},
	created: function () {},
	mounted: function () {
		// this.Index();
		this.GetDolarHoje();
		this.PopulaDados();
	},
	// funcao para renderizar o GraphJS após o DOM ser montado
	updated: function () {
		this.$nextTick(function () {
			this.DashBoard();
		});
	},
};
</script>
