<template>
  <div>
    <!-- <v-dialog v-model="ShowDialog" show attach="#dialog-holder" fullscreen hide-overlay persistent no-click-animation transition="dialog-bottom-transition"> -->
    <!-- <v-card>
      <v-app-bar color="primary" dense>
        <v-toolbar-title class="title white--text">Cadastro de Usuario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="Index()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <br />
      <v-card-text class="pl-1 pr-1"> -->
    <v-row dense>
      <v-col col="4" md="2" lg="2" offset="0" offset-md="2" offset-lg="2">
        <v-text-field outlined dense hide-details label="Id" v-model="Model.Id" readonly></v-text-field>
      </v-col>
      <v-col col="4" md="3" lg="1">
        <v-switch hide-details inset label="Ativo" color="primary" v-model="Model.FlagAtivo" :false-value="false" :true-value="true"></v-switch>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
        <v-text-field outlined dense hide-details label="Nome" v-model.trim="Model.Nome" ref="txtNome"></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
        <v-text-field outlined dense hide-details label="E-Mail" v-model.trim="Model.Email" ref="txtEmail"></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="4" offset="2">
        <v-text-field outlined dense hide-details label="Login" v-model.trim="Model.Login" ref="txtLogin"></v-text-field>
      </v-col>
      <v-col cols="4" offset="0">
        <v-text-field outlined dense hide-details label="Nova Senha" type="password" v-model.trim="Model.Senha" ref="txtSenha"></v-text-field>
      </v-col>
    </v-row>

    <v-row dense class="mt-5">
      <v-col col="12" md="8" lg="8" offset="0" offset-md="2" offset-lg="2">
        <v-row>
          <v-col>
            <v-btn block @click="ShowConfirmaExcluir = true" color="error" v-if="Model.Id != 0">Excluir Usuario</v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn block @click="Index()" color="warning">Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn block @click="ShowConfirmaSalvar = true" color="primary">Salvar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- </v-card-text> -->
    <!-- </v-card> -->
    <!-- </v-dialog> -->

    <confirm Msg="Excluir?" :Show="ShowConfirmaExcluir" v-on:confirm="Delete(), (ShowConfirmaExcluir = false)" v-on:cancel="ShowConfirmaExcluir = false"></confirm>
    <confirm Msg="Salvar?" :Show="ShowConfirmaSalvar" v-on:confirm="Save(), (ShowConfirmaSalvar = false)" v-on:cancel="ShowConfirmaSalvar = false"></confirm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SistemaUsuarioEdit",
  data() {
    return {
      ShowDialog: true,
      Model: {},
      ShowConfirmaExcluir: false,
      ShowConfirmaSalvar: false,
      Usuarios: [],
      Controllers: [],
      Membro: null,
      Header: [
        { text: "Membro", value: "Usuario.Nome", sortable: false },
        { text: "Membro", value: "Controller.Nome", sortable: false },
        { text: "", value: "Action", sortable: false, width: "70px", align: "center" },
      ],
    };
  },
  methods: {
    Edit(Id) {
      axios.get("/api/Sistema/Usuarios/Edit/".concat(Id)).then((response) => {
        let data = response.data.Data;
        if (data != null) {
          this.Model = data;
        }
      });
    },
    Save() {
      axios({ method: "post", data: this.Model, url: "/api/Sistema/Usuarios/Save" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Delete() {
      axios({ method: "delete", data: this.Model, url: "/api/Sistema/Usuarios/Delete" })
        .then((response) => {
          this.Index();
        })
        .catch((error) => {
          //
        });
    },
    Index() {
      this.$router.push("/Sistema/Usuarios/Index");
    },
    GetListas() {
      const instance = axios.create();

      const GetUsuarios = instance.get("/api/Listas/Usuarios");
      const GetControllers = instance.get("/api/Listas/Controllers");

      axios
        .all([GetUsuarios, GetControllers])
        .then(
          axios.spread((...responses) => {
            responses[0].data.forEach((item) => {
              this.Usuarios.push(item);
            });

            responses[1].data.forEach((item) => {
              this.Controllers.push(item);
            });
          })
        )
        .catch((errors) => {});
    },
    DelMembro(Item) {
      if (this.Model.FlagUsuarioDeController) {
        this.Model.Controllers.splice(this.Model.Controllers.indexOf(Item), 1);
      } else {
        this.Model.Usuarios.splice(this.Model.Usuarios.indexOf(Item), 1);
      }
    },
    AddMembro() {
      if (!this.Membro) {
        return;
      }

      if (this.Model.FlagUsuarioDeController) {
        let membro = this.Model.Controllers.find((x) => x.Controller.Id == this.Membro.Id);
        if (membro) {
          return;
        }

        this.Model.Controllers.push({ Id: 0, IdSistemaController: this.Membro.Id, IdSistemaUsuarioController: this.Model.Id, Controller: this.Membro });
      } else {
        let membro = this.Model.Usuarios.find((x) => x.Usuario.Id == this.Membro.Id);
        if (membro) {
          return;
        }

        this.Model.Usuarios.push({ Id: 0, IdSistemaUsuario: this.Membro.Id, IdSistemaUsuarioUsuario: this.Model.Id, Usuario: this.Membro });
      }
    },
    TipoUsuarioChange() {
      this.Membro = null;
    },
  },
  computed: {
    computedDataGrid() {
      if (this.Model.FlagUsuarioDeController) {
        return this.Model.Controllers;
      } else {
        return this.Model.Usuarios;
      }
    },
    computedHeader() {
      if (this.Model.FlagUsuarioDeController) {
        return this.Header.filter((item) => {
          return item.value != "Usuario.Nome";
        });
      } else {
        return this.Header.filter((item) => {
          return item.value != "Controller.Nome";
        });
      }
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id != null) {
      this.GetListas();
      this.Edit(id);
      this.$refs.txtNome.focus();
    } else {
      this.$router.push("/Sistema/Usuarios/Index");
    }
  },
};
</script>
