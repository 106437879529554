<template>
  
	<v-row>
    <v-col cols="10" offset="1" >
      <br>
      <v-alert border="right" colored-border type="error" elevation="2">
        500 - Erro na Requisição
        <hr><br>
        <span >
          {{ mensagem | replaceStr("\n",'&lt;br/&gt') }}
        </span>        
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
export default {
	name: 'Erro',
	data() { return {}; },
  props: ['mensagem'],
};
</script>