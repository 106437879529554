<template>
  <v-row>
    <v-col cols="6" offset="3">
      <br />
      <v-alert border="right" colored-border type="info" elevation="2">Fazendo de Logout</v-alert>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  name: "Logout",
  data() {
    return {};
  },
  methods: {
    Logout() {
      axios({
        method: "post",
        url: "/api/Sistema/Usuarios/Logout",
      }).then((response) => {
        this.$store.commit("SET_USROPE", {});
        this.$store.commit("SET_USERLOGGED", false);
        this.$store.commit("SET_HASHLOG", "");
        this.$store.commit("SET_MODORELATORIO", false);
        this.$router.push("/Login");
      });
    },
    LogoutDev() {
      this.$store.commit("SET_USROPE", {});
      this.$store.commit("SET_HASHLOG", "");
      this.$store.commit("SET_USERLOGGED", false);
      this.$router.push("/Login");
    },
  },
  mounted: function () {
    this.Logout();
    //this.LogoutDev();
  },
};
</script>
